/* eslint-disable no-unused-vars */

export default {
  init() {
    // JavaScript to be fired on all pages
    jQuery('.slick-slider').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
    });
    jQuery('.gallery-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      dots: true,
      asNavFor: '.gallery-caption',
      adaptiveHeight: true,
    });
    jQuery('.gallery-caption').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      arrows: false,
      dots: false,
    });
    jQuery('ul.tabs').on('change.zf.tabs', function (event, tab) {
      jQuery('.gallery-slider').slick('setPosition');
      jQuery('.gallery-caption').slick('setPosition');
    });
    jQuery('#menu-toggler').on('click', function () {
      jQuery(this).toggleClass('active');
    });
    // jQuery.localScroll({duration:200});
    jQuery(document).foundation();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};

/* eslint-enable no-unused-vars */
